import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, withPrefix } from 'gatsby';

// Images
import logo from '../assets/img/THNG-logo.svg';

// Icons
import linkedin_icon from "../assets/icons/linkedin-icon.svg"
import instagram_icon from "../assets/icons/instagram-icon.svg"
import twitter_icon from "../assets/icons/twitter-icon.svg"

export default function Footer() {
	const referencePath = React.useCallback(() => {
		if (typeof window === 'undefined') {
			return '/#referenzen';
		}

		const path = window.location.pathname;

		if (path.startsWith('/kommune') || path.startsWith('/#') || path === '/') {
			return '#referenzen';
		} else {
			return '/#referenzen';
		}
	}, []);

	const idPath = React.useCallback((id) => {
		if (typeof window === 'undefined') {
			return `/${id}`;
		}

		const path = window.location.pathname;

		if (
			path.startsWith('/uber-uns') ||
			path.startsWith('/impressum') ||
			path.startsWith('/datenschutzerklarung')
		) {
			return `/${id}`;
		} else {
			return id;
		}
	}, []);

	return (
		<footer className="mlg_site-footer">
			<div className="mlg_sf-container">
				<Container>
					<Row>
						<Col xl={4} lg={12}>
							<img src={logo} alt="THNG Logo" className="mlg_sf-logo" />

							<h4 className="mb-3-5rem mb-md-48px mb-sm-25px mb-xs-25px br-md-hide">
								Sichere Online Votings. Ihr <br />
								professioneller Service für Wahlen, <br />
								Befragungen und Abstimmungen.
							</h4>

							<div className="mlg_sf-copyright">
								<p>© {new Date().getFullYear()} – THNG GmbH. Alle Rechte vorbehalten.</p>
							</div>
						</Col>

						<Col xl={2} lg={6} md={6} className="mb-md-40px">
							<h4>Unternehmen</h4>

							<nav>
								<ul>
									<li>
										<Link to="/uber-uns">
											Über uns
										</Link>
									</li>
									<li>
										<Link to="/#service">
											Service
										</Link>
									</li>
									{/* <li>
										<Link to={idPath('#preise')}>
											Preise
										</Link>
									</li> */}
								</ul>
							</nav>
						</Col>

						<Col xl={2} lg={6} md={6} className="mb-md-40px">
							<h4>Online Voting</h4>

							<nav>
								<ul>
									<li>
										<Link to={referencePath('#referenzen')}>
											Referenzprojekte
										</Link>
									</li>
									<li>
										<Link to={idPath('#sicherheit')}>
											Sicherheit
										</Link>
									</li>
									<li>
										<Link to={idPath('#vorteile')}>
											Vorteile
										</Link>
									</li>
								</ul>
							</nav>
						</Col>

						<Col xl={4} lg={12}>
							<Row>
								<Col lg={6} md={6}>
									<h4>Rechtliches</h4>

									<nav>
										<ul>
											<li>
												<a href={withPrefix('/datenschutzerklarung')} target="_blank">
													Datenschutz
												</a>
											</li>
											<li>
												<a href={withPrefix('/agb')} target="_blank">
													AGB
												</a>
											</li>
											<li>
												<a href={withPrefix('/impressum')} target="_blank">
													Impressum
												</a>
											</li>
											<li>
												<Link to='#kontakt'>
													Kontakt
												</Link>
											</li>
										</ul>
									</nav>
								</Col>
								<Col lg={6} md={6}>
								<h4>Social Media</h4>

								<div className="mlg_social-media">
									<ul>
									<li>
										<a href="https://de.linkedin.com/company/thng">
										<img src={linkedin_icon} alt="Linkedin Icon" />
										<span>Linkedin</span>
										</a>
									</li>
									<li>
										<a href="https://www.instagram.com/thng_evotings/">
										<img src={instagram_icon} alt="Instagram Icon" />
										<span>Instagram</span>
										</a>
									</li>
									<li>
										<a href="https://twitter.com/thng_eVotings">
										<img src={twitter_icon} alt="Twitter Icon" />
										<span>Twitter</span>
										</a>
									</li>
									</ul>
								</div>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</footer>
	);
}
