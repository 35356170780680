import React from 'react';
import { Container } from 'react-bootstrap';

// Components
import SEO from '../components/seo';
import Header from '../components/header';
import Footer from '../components/footer';

export default function Kommune() {
	return (
		<div id="page-404" className="mlg_site-container">
			<SEO title="404: Not found" />

			<Header />

			<main className="mlg_site-content">
				<Container>
					<div className="text-center pt-10rem pb-10rem">
						<h1>NOT FOUND</h1>
						<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
					</div>
				</Container>
			</main>

			<Footer />
		</div>
	);
}
